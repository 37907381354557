code {
  font-family: "Manrope";
}
body {
  margin: 0;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body::-webkit-scrollbar {
    width: 0.5em;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
